.modal {
  z-index: 500;
  background: #fff;
  border-radius: 4px;
  padding: 2px;
  position: fixed;
  top: 330px;
  left: 50%;
}

.modal.size-m {
  width: 600px;
  height: 400px;
}

.modal.size-l {
  width: 700px;
  height: 500px;
}

.modal.size-s {
  width: 500px;
  height: 300px;
}

.modal-bg {
  height: 100%;
  width: 100%;
  opacity: .5;
  z-index: 100;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-close {
  color: #fff;
  cursor: pointer;
  opacity: .3;
  font-size: 30px;
  font-weight: bold;
  line-height: .5;
  position: absolute;
  top: -20px;
  right: -19px;
}

.modal-close:hover {
  opacity: 1;
}

/*# sourceMappingURL=index.0767271b.css.map */
