.modal {
	padding: 2;
	left: 50%;
	top: 330px;	
	position: fixed;
	z-index: 500;
	background: #fff;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.modal.size-m {
	width: 600px;
	height: 400px;
}

.modal.size-l {
	width: 700px;
	height: 500px;
}

.modal.size-s {
	width: 500px;
	height: 300px;
}

.modal-bg {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: #000;
	opacity: 0.5;
	top: 0;
	left: 0;
	z-index: 100;
}

.modal-close {
    color: #fff;
    font-size: 30px;
    line-height: .5;
    position: absolute;
    top: -20px;
    right: -19px;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.3;
}

.modal-close:hover {
	opacity: 1;
}